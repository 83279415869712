import React, { useState, useEffect } from "react";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import { MDXProvider } from "@mdx-js/react";
import { MDXComponents } from "mdx/types.js";
import FaqContent from "./faq.mdx";
import GettingStarted from "./gettingStarted.mdx";
import classNames from "./styles.module.css";

const components: MDXComponents = {
  h1: ({ color, ref, ...props }) => (
    <Typography variant="h1" gutterBottom {...props} />
  ),
  h2: ({ color, ref, ...props }) => (
    <Typography {...props} className={classNames.headingTitle} />
  ),
  h3: ({ color, ref, ...props }) => (
    <Typography variant="h3" gutterBottom {...props} />
  ),
  h4: ({ color, ref, ...props }) => (
    <Typography variant="h4" gutterBottom {...props} />
  ),
  h5: ({ color, ref, ...props }) => (
    <Typography variant="h5" gutterBottom {...props} />
  ),
  h6: ({ color, ref, ...props }) => (
    <Typography variant="h6" gutterBottom {...props} />
  ),
  p: ({ color, ref, ...props }) => <Typography paragraph {...props} />,
  ul: (props) => (
    <ul
      style={{ listStyleType: "disc", paddingLeft: "2rem", margin: "0.5rem 0" }}
      {...props}
    />
  ),
  li: ({ color, ref, ...props }) => (
    <Typography component="li" gutterBottom {...props} />
  ),
};

interface TabPanelProps {
  children: React.ReactElement;
  currentTab: number;
  index: number;
  id: string;
  ariaLabelledby: string;
}

const TabPanel = ({
  children,
  currentTab,
  index,
  id,
  ariaLabelledby,
}: TabPanelProps) => (
  <Box
    role="tabpanel"
    hidden={currentTab !== index}
    id={id}
    aria-labelledby={ariaLabelledby}
    pt="100px"
  >
    {currentTab === index && children}
  </Box>
);

const FAQ = () => {
  const { search: urlParams, href: url } = window.location;
  const isResourcesEnabled = urlParams.includes("resources");
  const [currentTab, setCurrentTab] = useState(isResourcesEnabled ? 1 : 0);

  useEffect(() => {
    if (url.includes("#admin")) {
      const section = document.getElementById("admin");

      if (section) {
        setTimeout(() => {
          window.scrollTo({ top: section.offsetTop - 20, behavior: "smooth" });
        }, 1000);
      }
    }
  }, []);

  const handleChange = (_: React.ChangeEvent<{}>, value: number) => {
    setCurrentTab(value);
  };

  return (
    <MDXProvider components={components}>
      {isResourcesEnabled ? (
        <>
          <Box className={classNames.tabContainer}>
            <Box className={classNames.container}>
              <Tabs value={currentTab} onChange={handleChange}>
                <Tab label="FAQs" id="tab-faq" aria-controls="tabpanel-faq" />
                <Tab
                  label="Getting Started"
                  id="tab-started"
                  aria-controls="tabpanel-started"
                />
              </Tabs>
            </Box>
          </Box>
          <TabPanel
            currentTab={currentTab}
            index={0}
            id="tabpanel-faq"
            ariaLabelledby="tab-faq"
          >
            <FaqContent />
          </TabPanel>
          <TabPanel
            currentTab={currentTab}
            index={1}
            id="tabpanel-started"
            ariaLabelledby="tab-started"
          >
            <GettingStarted components={components} />
          </TabPanel>
        </>
      ) : (
        <FaqContent components={components} />
      )}
    </MDXProvider>
  );
};

export default FAQ;
